.gameDev__container {
  grid-column: 3/-3;
}

.gameDev__hero--container {
  position: relative;
  height: 50rem;
  width: 100%;
  z-index: 1;
}

.view {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 50;
}
